import { api, param2query } from "./api.service";

export const downloadRelatorioAuditoriaContas = (filtro) => {
  return api.get(
    'relatorio-auditoria-contas/download?' + param2query(filtro),
    { responseType: 'blob' }
  ).then((response) => {
    const href = URL.createObjectURL(response.data);

    let filename = 'relatorio-auditoria-retrospectiva-contas.xlsx';
    if (response && response.headers && response.headers['download-filename']) {
      filename = response.headers['download-filename'];
    }

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link);
  })
};
